<template>
  <section class="Wrapper Wrapper--max PrivacyPolicy">
    <h1 class="Title h1 PrivacyPolicy-Title">
      Privacy Policy
    </h1>
    <ul class=" PrivacyPolicy-List">
      <li class="PrivacyPolicy-Item">
        <h3 class="Title h3 PrivacyPolicy-ItemTitle">INTRODUCTION</h3>
        <p class="Text">
          This Privacy Policy, which was last updated on 04/06/2020, sets out the data processing practices carried out
          by BigBetDeal. Here at BigBetDeal we like making things simple and easy for our customers and that includes
          being clear, transparent and open about what we collect, and how and why we use the data we collect, so this
          page goes into great detail.
        </p>
        <p class="Text">
          Our Policy explains how we use your personal data, whether you are just visiting, making a deposit or playing
          one of our games. This policy includes information you may not already know about your data privacy rights,
          circumstances in which we share and obtain information about you and how we use it to personalize the
          marketing you receive, so please take time to read all the sections carefully.
        </p>
        <p class="Text">
          If we make any material updates to this Policy, we will inform you.
        </p>
        <p class="Text">
          Please note that all our employees undertake regular training on Information Security and are required to
          comply with confidentially obligations as well as internal policies and procedures whenever they handle your
          information.
          We hope you will find this page helpful, but should you have any concerns or questions, regarding Data
          Protection, please feel free to contact our Data Protection Officer at info@bigbetdeal.co.uk.
        </p>
      </li>
      <li class="PrivacyPolicy-Item">
        <h3 class="Title h3 PrivacyPolicy-ItemTitle">OUR PRIVACY POLICY PROMISE</h3>
        <p class="Text">
          Our Privacy Policy Promise BigBetDeal will always value and respect the privacy of each and every one of its
          customers. Your privacy is important both to you and to us and we make the following promise to you:
        </p>
        <p class="Text">
          BigBetDeal is committed to protecting your privacy. We believe in using your personal information to make
          things simpler and better for you. We will always keep your personal information safe. We’ll be clear and open
          with you about why we collect your personal information and how we use it. Where you have choices or rights,
          we’ll explain them to you and respect your wishes.
        </p>
      </li>
      <li class="PrivacyPolicy-Item">
        <h3 class="Title h3 PrivacyPolicy-ItemTitle">HOW WE USE YOUR INFORMATION</h3>
        <p class="Text">
          We process the Personal Information we collect from you in order to deliver our services. In particular, we
          will use your data for the following purposes:
        </p>
        <ul class="PrivacyPolicy-SubList">
          <li class="PrivacyPolicy-SubItem">
            <p class="Text">
              Processing your bets and transactions. This includes your use of credit card and online payment systems;
            </p>
          </li>
          <li class="PrivacyPolicy-SubItem">
            <p class="Text">
              Providing you with gaming and other ancillary services that you seek from our Website;
            </p>
          </li>
          <li class="PrivacyPolicy-SubItem">
            <p class="Text">
              Providing registered players with information about our promotional offers, or providing promotional
              information from our selected business partners, associates and affiliates (only if players specifically
              consented to receiving such marketing material);
            </p>
          </li>
          <li class="PrivacyPolicy-SubItem">
            <p class="Text">
              Monitoring and investigating transactions for the purpose of preventing fraud, terms abuse, money
              laundering and other illegal or irregular gaming activities;
            </p>
          </li>
        </ul>
      </li>
      <li class="PrivacyPolicy-Item">
        <h3 class="Title h3 PrivacyPolicy-ItemTitle">OBTAINING PERSONAL INFORMATION</h3>
        <p class="Text">
          We shall not collect any Personal Information about you without your knowledge. We may, however, automatically
          collect certain data about you where you would have provided such information through the use of our services
          and through your interactions with us.
        </p>
        <p class="Text">
          We may also lawfully receive certain Personal Information from online vendors and service providers, such as
          fraud prevention companies. In addition, we retain the right to engage the services of third-party providers
          to render technical support, so as to process your online transactions and source gaming content.
        </p>
        <p class="Text">
          Please understand that we may be granted access to any information you may provide to such vendors, service
          providers and third-party e-commerce services. Rest assured that we will use and safeguard any Personal
          Information so obtained, as set out in this Policy. Any information that you provide will only be disclosed to
          third parties outside the Company in accordance with this Privacy Policy, and we shall take any necessary
          steps to ensure that our agreements with third-party service providers always protect your private
          information.
        </p>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped lang="scss">
.PrivacyPolicy {
  padding: 140px 0 100px;

  &-Title {
    margin-bottom: 80px;
  }

  &-Item {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-ItemTitle {
    margin-bottom: 40px;
  }

  &-SubItem {
    margin-left: 60px;
    padding-left: 20px;
    list-style: decimal;

    &::marker {
      color: var(--color-text);
    }
  }

  &-SubItem {

  }
}
</style>
